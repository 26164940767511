
  import { defineComponent, onMounted, ref } from "vue";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import { useI18n } from "vue-i18n";
  import {
    COMPONENT_NAME,
    PAGE_TITLE_PATH,
SWAL_MESSAGES,
  } from "@/domain/Constant";
  import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { FeedBuyModel } from "@/domain/feed/feedBuy/model/FeedBuyModel";
import store from "@/presentation/store";
import SwalNotification from "@/presentation/plugins/SwalNotification";
  
  export default defineComponent({
    name: COMPONENT_NAME.CREATE_ANIMAL,
    components: { Field, Form, ErrorMessage },
    setup() {
      const { t, te } = useI18n();
      const swalNotification = new SwalNotification();
      const scrollElRef = ref<null | HTMLElement>(null);
      const submitButton = ref<HTMLElement | null>(null);

      const feedBuyModel : FeedBuyModel ={
      }

      const productController = store.state.ControllersModule.productController;

      onMounted(() => {
        setCurrentPageTitle(t(PAGE_TITLE_PATH.BUY_FEED)); 
        if (scrollElRef.value) {
          scrollElRef.value.scrollTop = 0;
        }
      });
  
      const handleSubmit = () => {
        if (submitButton.value) {
          // Activate indicator
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        productController.buyFeed(feedBuyModel)
        .then((response) => {
          if (response.isSuccess) {
            swalNotification.success(
              t(SWAL_MESSAGES.SUCCESS_BUY_FOOD),
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          } else {
            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      };
  
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
  
      return {
        PAGE_TITLE_PATH,
        translate,
        handleSubmit,
        feedBuyModel,
      };
    },
  });
  