<template>
    <!--begin::Basic info-->
    <div class="card">
      <!--begin::Card header-->
      <div class="card-header border-0 pt-3">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">
            {{ $t(PAGE_TITLE_PATH.BUY_FEED) }}
          </h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header-->
  
      <!--begin::Content-->
      <div id="kt_account_profile_details" class="collapse show">
        <!--begin::Form-->
        <Form
          id="kt_account_profile_details_form"
          class="form"
          novalidate="novalidate"
          @submit="handleSubmit"
        >
          <!--begin::Card body-->
          <div class="card-body border-top p-9">
  
            <div class="row mb-2">
              <div class="col-md-6">
                <div class="row mb-5">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">
                    <span class="required">{{
                      $t("menuPages.buyFeed.feed")
                    }}</span>
                  </label>
                  <!--end::Label-->
  
                  <!--begin::Col-->
                  <div class="col-lg-6 fv-row">
                    <Field
                    v-model="feedBuyModel.name"
                      type="text"
                      name="name"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('menuPages.buyFeed.feed')"
                    />
                  </div>
                  <!--end::Col-->
                </div>
              </div>
              <div class="col-md-6">
                <div class="row mb-5">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">
                    <span class="required">{{ $t("menuPages.buyFeed.description") }}</span>
                  </label>
                  <!--end::Label-->
  
                  <!--begin::Col-->
                  <div class="col-lg-6 fv-row">
                    <Field
                    v-model="feedBuyModel.description"
                      type="text"
                      name="description"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('menuPages.buyFeed.description')"
                    />
                  </div>
                  <!--end::Col-->
                </div>
              </div>
            </div>
  
            <div class="row mb-2">
              <div class="col-md-6">
                <div class="row mb-5">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">
                    <span class="required">{{ $t("menuPages.buyFeed.unitPrice") }}</span>
                  </label>
                  <!--end::Label-->
  
                  <!--begin::Col-->
                  <div class="col-lg-6 fv-row">
                    <Field
                    v-model="feedBuyModel.unitPrice"
                      type="number"
                      min="1"
                      name="unitPrice"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('menuPages.buyFeed.unitPrice')"
                    />
                  </div>
                  <!--end::Col-->
                </div>
              </div>

              <div class="col-md-6">
                <div class="row mb-5">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">
                    <span class="required">{{ $t("menuPages.buyFeed.quantity") }}</span>
                  </label>
                  <!--end::Label-->
  
                  <!--begin::Col-->
                  <div class="col-lg-6 fv-row">
                    <Field
                    v-model="feedBuyModel.quantity"
                      type="number"
                      min="10"
                      name="quantity"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('menuPages.buyFeed.quantity')"
                    />
                  </div>
                  <!--end::Col-->
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-6">
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">
                    <span class="required">{{ $t("menuPages.buyFeed.purchaseDate") }}</span>
                  </label>
                  <!--end::Label-->
  
                  <!--begin::Col-->
                  <div class="col-lg-6 fv-row">
                    <Field
                    v-model="feedBuyModel.purchaseDate"
                      type="date"
                      name="purchaseDate"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('menuPages.buyFeed.purchaseDate')"
                    />
                    <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="date" />
                    </div>
                  </div>
                  </div>
                  <!--end::Col-->
                </div>
              </div>
            </div>
          </div>
          <!--end::Card body-->
          <!--begin::Actions-->
          <div class="card-footer d-flex justify-content-end py-6 px-9">
                    <!--begin::Submit button-->
            <button
              type="submit"
              id="kt_account_profile_details_submit"
              ref="submitButton"
              class="btn btn-primary"
            >
              <span class="indicator-label"> {{ $t("menuPages.buyFeed.button.buy") }} </span>
              <span class="indicator-progress">
                {{ $t("pleaseWait") }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
                    <!--end::Submit button-->
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Basic info-->
  </template>
  
  <style lang="scss">
  .aside-menu .menu .menu-sub .menu-item a a.menu-link {
    padding-left: calc(0.75rem + 25px);
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    transition: none;
    outline: none !important;
  }
  
  .aside-menu .menu .menu-sub .menu-sub .menu-item a a.menu-link {
    padding-left: calc(1.5rem + 25px);
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    transition: none;
    outline: none !important;
  }
  </style>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import { useI18n } from "vue-i18n";
  import {
    COMPONENT_NAME,
    PAGE_TITLE_PATH,
SWAL_MESSAGES,
  } from "@/domain/Constant";
  import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { FeedBuyModel } from "@/domain/feed/feedBuy/model/FeedBuyModel";
import store from "@/presentation/store";
import SwalNotification from "@/presentation/plugins/SwalNotification";
  
  export default defineComponent({
    name: COMPONENT_NAME.CREATE_ANIMAL,
    components: { Field, Form, ErrorMessage },
    setup() {
      const { t, te } = useI18n();
      const swalNotification = new SwalNotification();
      const scrollElRef = ref<null | HTMLElement>(null);
      const submitButton = ref<HTMLElement | null>(null);

      const feedBuyModel : FeedBuyModel ={
      }

      const productController = store.state.ControllersModule.productController;

      onMounted(() => {
        setCurrentPageTitle(t(PAGE_TITLE_PATH.BUY_FEED)); 
        if (scrollElRef.value) {
          scrollElRef.value.scrollTop = 0;
        }
      });
  
      const handleSubmit = () => {
        if (submitButton.value) {
          // Activate indicator
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        productController.buyFeed(feedBuyModel)
        .then((response) => {
          if (response.isSuccess) {
            swalNotification.success(
              t(SWAL_MESSAGES.SUCCESS_BUY_FOOD),
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          } else {
            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      };
  
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
  
      return {
        PAGE_TITLE_PATH,
        translate,
        handleSubmit,
        feedBuyModel,
      };
    },
  });
  </script>
  